

























































































































import { Component, Vue } from 'vue-property-decorator';
import i18n from 'vue-i18n';
import ExpandableDescriptionComponent from '@/components/ExpandableDescriptionComponent.vue';
import { ActivityMethod, Limits, WeightLimits } from '@/store/modules/query/types';
import Unwatcher from '@/utils/unwatcher';
import { RootState } from '@/store/root-state';

@Component({
  components: {
    ExpandableDescriptionComponent,
  },
})
export default class QuerySettingsComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  includeJoinDateInSurvey: boolean = true;
  limits: Limits = {
    minDistanceM: {},
    maxDistanceM: {},
    minDurationS: {},
    maxDurationS: {},
  };

  minCellN: number = 0;
  weightLimits: WeightLimits = {
    minClassN: 0,
    minVariableN: 0,
    warnVariableN: 0,
  };

  activityMethod: ActivityMethod = ActivityMethod.endActivity;
  vtiReselementMerge: boolean = false;
  // Enum constants for use in template
  amEndActivity = ActivityMethod.endActivity;
  amNotHomeSleep = ActivityMethod.notHomeSleep;

  public async created() {
    const self = this;
    const selfAny : any = this;

    // When filter state changes => new fetch
    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) => getters.querySettingsState,
      (newValue: any|null, _oldValue: any|null) => {
        // Update component variables from new store state
        const s = self.newComponentState();
        Object.keys(s).forEach((key : string) => {
          selfAny[key] = s[key];
        });
      },
    ));
  }

  public beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return this.newComponentState();
  }

  newComponentState() : any {
    return {
      includeJoinDateInSurvey: this.$store.state.querySettings.includeJoinDateInSurvey,
      limits: {
        minDistanceM: this.$store.state.querySettings.limits.minDistanceM,
        maxDistanceM: this.$store.state.querySettings.limits.maxDistanceM,
        minDurationS: this.$store.state.querySettings.limits.minDurationS,
        maxDurationS: this.$store.state.querySettings.limits.maxDurationS,
      } as Limits,
      minCellN: this.$store.state.querySettings.minCellN,
      weightLimits: {
        minClassN: this.$store.state.querySettings.weightLimits.minClassN,
        minVariableN: this.$store.state.querySettings.weightLimits.minVariableN,
        warnVariableN: this.$store.state.querySettings.weightLimits.warnVariableN,
      } as WeightLimits,
      activityMethod: this.$store.state.querySettings.activityMethod,
      vtiReselementMerge: this.$store.state.querySettings.vtiReselementMerge,
    };
  }

  onOk() {
    // Commit to store with copies of deep objects
    this.$store.commit('querySettings/set', {
      includeJoinDateInSurvey: this.includeJoinDateInSurvey,
      limits: { ...this.limits },
      minCellN: this.minCellN,
      weightLimits: { ...this.weightLimits },
      activityMethod: this.activityMethod,
      vtiReselementMerge: this.vtiReselementMerge,
    });
    this.$emit('close');
  }

  onClose() {
    this.$emit('close');
  }
}
