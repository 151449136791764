






















import { Component, Vue } from 'vue-property-decorator';
import { AggregatedMode, AggregatedActivity } from '@/store/modules/mode-activity/types';
import resolveLabel from '@/utils/I18nLabel';
import Unwatcher from '@/utils/unwatcher';
import { RootState } from '@/store/root-state';
import i18n from '@/i18n';
import { SampleVariable, SampleClass } from '@/store/modules/sample/types';
import { flavor } from '@/flavor';
import { Flavor } from '@/flavor/types';
import { findSampleVariableByAnswerOptionKey, findSampleVariableByQuestionKey } from '@/utils/sample';
import {
  DropDownMenuId,
  AdvancedItems,
  DropDownMenu,
  MenuItem,
  NO_ANSWER_FILTER_ITEM_ID,
} from './types';
import {
  updateAllChecked,
} from './dropdownUtils';
import FilterBarDropDownComponent from './FilterBarDropDownComponent.vue';
import FilterBarDateTimeDropDownComponent from './FilterBarDateTimeDropDownComponent.vue';

/** Shows filter menus to configure filter state. */
@Component({
  props: {},
  components: {
    FilterBarDropDownComponent,
    FilterBarDateTimeDropDownComponent,
  },
})
export default class FilterBarComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  menu : Array<DropDownMenu> = [];
  dateTimeMenuId = DropDownMenuId.dateTime;
  flavor: Flavor = flavor;
  travelViewer = Flavor.travelViewer;

  public created() {
    // Watch modeActivity and questionnaire store to rebuild menu
    const rebuildMenuFn = (
      newStoreValue: Array<number>|null, oldStoreValue: Array<number>|null,
    ) => {
      this.menu = this.buildMenu();
    };
    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) : any => state.modeActivity.aggregatedModes,
      rebuildMenuFn.bind(this),
    ));
    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) : any => state.modeActivity.aggregatedActivities,
      rebuildMenuFn.bind(this),
    ));
    this.unwatcher.push(this.$store.watch(
      (state: RootState, getters: any) : any => getters['sample/isLoaded'],
      rebuildMenuFn.bind(this),
    ));
  }

  beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      menu: this.buildMenu(),
    };
  }

  buildMenu(): Array<DropDownMenu> {
    // This method and descendants are called from a watcher which causes this.$t
    // to not work. Instead this method uses i18n.tc() which is equivalent to
    // this.$t but works from watch callbacks.
    switch (flavor) {
      case Flavor.travelViewer:
        return this.buildTravelViewerMenu();
      case Flavor.activeViewer:
        return this.buildActiveViewerMenu();
      default:
        return [];
    }
  }

  buildTravelViewerMenu(): Array<DropDownMenu> {
    const menu = [
      {
        id: DropDownMenuId.mode,
        label: i18n.tc('analysis.menu.mode'),
        isActive: false,
        items: [
          ...this.getModeMenuItems(),
        ],
        allChecked: true,
      },
      {
        id: DropDownMenuId.activity,
        label: i18n.tc('analysis.menu.activity'),
        isActive: false,
        items: [
          ...this.getActivityMenuItems(),
        ],
        allChecked: true,
      },
      {
        id: DropDownMenuId.question,
        label: i18n.tc('analysis.menu.question'),
        isActive: false,
        items: [
          ...this.getQuestionMenuItems(),

        ],
        allChecked: false,
      },
      {
        id: DropDownMenuId.dateTime,
        label: i18n.tc('analysis.menu.date-time'),
        isActive: false,
        items: [],
        allChecked: false,
      },
      {
        id: DropDownMenuId.advanced,
        label: i18n.tc('analysis.menu.advanced'),
        isActive: false,
        items: [
          /*
          { // Hidden for now as home area is now in question drop down
            label: i18n.tc('analysis.menu.home-area'),
            icon: 'home-city',
            iconset: 'mdi',
            isCheck: false,
            filterItemId: AdvancedItems.homeArea,
            // => open modal where user can select which home area to include
          },
          */
          {
            label: i18n.tc('analysis.menu.trip-distance'),
            icon: 'ruler',
            iconset: 'mdi',
            isCheck: false,
            filterItemId: AdvancedItems.tripDistance,
            // => open modal to set trip distance filter
          },
          {
            label: i18n.tc('analysis.menu.trip-duration'),
            icon: 'timer',
            iconset: 'mdi',
            isCheck: false,
            filterItemId: AdvancedItems.tripDuration,
            // => open modal to set trip duration filter
          },
          /*
          {
            label: i18n.tc('analysis.menu.filter-editor'),
            icon: 'code-braces',
            iconset: 'mdi',
            isCheck: false,
            filterItemId: AdvancedItems.filterEditor,
            // => open modal where user can make advanced filters
          },
          */
          {
            label: i18n.tc('analysis.menu.reset-all-filters'),
            icon: 'restore',
            iconset: 'mdi',
            isCheck: false,
            checked: null,
            filterItemId: AdvancedItems.resetAllFilters,
          },
          {
            label: i18n.tc('analysis.menu.edit-modes'),
            icon: 'wrench',
            iconset: 'mdi',
            isCheck: false,
            checked: null,
            filterItemId: AdvancedItems.editAggregatedModes,
          },
          {
            label: i18n.tc('analysis.menu.edit-activities'),
            icon: 'wrench',
            iconset: 'mdi',
            isCheck: false,
            checked: null,
            filterItemId: AdvancedItems.editAggregatedActivities,
          },
          {
            label: i18n.tc('analysis.menu.edit-query-settings'),
            icon: 'wrench',
            iconset: 'mdi',
            isCheck: false,
            checked: null,
            filterItemId: AdvancedItems.editQuerySettings,
          },
        ],
        allChecked: false,
      },
    ] as Array<DropDownMenu>;
    menu.forEach((dropdown) => updateAllChecked(dropdown));
    return menu;
  }

  /** Get menu items for aggregated modes */
  getModeMenuItems() : Array<MenuItem> {
    const { filter } = this.$store.state;
    return this.$store.state.modeActivity.aggregatedModes.map((m : AggregatedMode) => {
      const mode = {
        label: resolveLabel(null, m.labelTranslations),
        icon: undefined,
        iconSet: undefined,
        isCheck: true,
        checked: filter.aggregatedModes.indexOf(m.id) !== -1,
        filterParentId: -1,
        filterItemId: m.id,
        isMissingValue: false,
        custom: undefined,
      } as MenuItem;
      return mode;
    });
  }

  /** Get menu items for aggregated activities */
  getActivityMenuItems() : Array<MenuItem> {
    const { filter } = this.$store.state;
    return this.$store.state.modeActivity.aggregatedActivities.map((a : AggregatedActivity) => {
      const activity = {
        label: resolveLabel(null, a.labelTranslations),
        icon: undefined,
        iconSet: undefined,
        isCheck: true,
        checked: filter.aggregatedActivities.indexOf(a.id) !== -1,
        filterParentId: -1,
        filterItemId: a.id,
        isMissingValue: false,
        custom: undefined,
      } as MenuItem;
      return activity;
    });
  }

  /** Get menu items for sample variables and classes */
  getQuestionMenuItems() : Array<MenuItem> {
    const { filter } = this.$store.state;
    const result : Array<MenuItem> = [];
    this.$store.state.sample.variables.forEach((sv : SampleVariable) => {
      if (!sv.isShare) {
        // Skip total SampleVariable
        return;
      }

      if (sv.classes.length > 0) {
        result.push({
          label: resolveLabel(sv.label, sv.labelTranslations),
          icon: undefined,
          iconSet: undefined,
          isCheck: false,
          checked: false,
          filterParentId: -1,
          filterItemId: -1,
          isMissingValue: false,
          custom: undefined, // CustomItem.question,
        } as MenuItem);
        sv.classes.forEach((c : SampleClass) => {
          result.push({
            label: resolveLabel(c.label, c.labelTranslations),
            icon: undefined,
            iconSet: undefined,
            isCheck: true,
            checked: this.$store.getters['filter/isSampleClassEnabled'](sv.id, c.id),
            filterParentId: sv.id,
            filterItemId: c.id,
            isMissingValue: false,
            custom: undefined,
          } as MenuItem);
        });
        // Missing entry
        result.push({
          label: i18n.tc('analysis.question.missing-answer'),
          icon: undefined,
          iconSet: undefined,
          isCheck: true,
          checked: this.$store.getters['filter/isSampleClassEnabled'](sv.id, null),
          filterParentId: sv.id,
          filterItemId: NO_ANSWER_FILTER_ITEM_ID,
          isMissingValue: true,
          custom: undefined,
        } as MenuItem);
      }
    });
    return result;
  }

  buildActiveViewerMenu(): Array<DropDownMenu> {
    const menu = [
      this.getSampleVariableMenu(
        DropDownMenuId.gender,
        findSampleVariableByQuestionKey(this.$store.state, 'GENDER'),
        i18n.tc('analysis.menu.gender'),
      ),
      this.getSampleVariableMenu(
        DropDownMenuId.age,
        findSampleVariableByAnswerOptionKey(this.$store.state, 'BIRTH_YEAR', 'BIRTH_YEAR'),
        i18n.tc('analysis.menu.age'),
      ),
      this.getSampleVariableMenu(
        DropDownMenuId.distanceToWork,
        findSampleVariableByQuestionKey(this.$store.state, 'DISTANCE_TO_WORK'),
        i18n.tc('analysis.menu.distance-to-work'),
      ),
      this.getSampleVariableMenu(
        DropDownMenuId.workplaceSite,
        findSampleVariableByQuestionKey(this.$store.state, 'WORKPLACE_SITE'),
        i18n.tc('analysis.menu.workplace-site'),
      ),
    ] as Array<DropDownMenu|null>;
    // Keep only non-null menus
    return menu.filter((value) => value !== null) as Array<DropDownMenu>;
  }

  getSampleVariableMenu(menuId: DropDownMenuId, sv: SampleVariable|null, label: string) : DropDownMenu|null {
    if (sv === null) {
      return null;
    }
    return {
      id: menuId,
      label,
      isActive: false,
      items: [
        ...this.getSampleVariableMenuItems(sv),
      ],
      allChecked: true,
    } as DropDownMenu;
  }

  /** Get menu items for aggregated modes */
  getSampleVariableMenuItems(variable : SampleVariable) : Array<MenuItem> {
    const result = variable.classes.map((c : SampleClass) => {
      const mode = {
        label: c.label,
        icon: undefined,
        iconSet: undefined,
        isCheck: true,
        checked: this.$store.getters['filter/isSampleClassEnabled'](variable.id, c.id),
        filterParentId: variable.id,
        filterItemId: c.id,
        isMissingValue: false,
        custom: undefined,
      } as MenuItem;
      return mode;
    });
    // Missing entry
    result.push({
      label: i18n.tc('analysis.question.missing-answer'),
      icon: undefined,
      iconSet: undefined,
      isCheck: true,
      checked: this.$store.getters['filter/isSampleClassEnabled'](variable.id, null),
      filterParentId: variable.id,
      filterItemId: NO_ANSWER_FILTER_ITEM_ID,
      isMissingValue: true,
      custom: undefined,
    } as MenuItem);
    return result;
  }
}
