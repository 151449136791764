
































































import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '../utils/unwatcher';

function sToMinutes(s : number|null) : number|null {
  return s !== null ? s / 60 : null;
}

function minutesToS(minutes : number|null) : number|null {
  return minutes !== null ? minutes * 60 : null;
}

/** Shows the current filter state. */
@Component({
})
export default class TripDurationEditComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  minMinutes : number|null = null;
  maxMinutes : number|null = null;

  public created() {
    const watchChangedFn = (
      newStoreValue: Array<any>,
      oldStoreValue: Array<any>,
    ) => {
      this.minMinutes = sToMinutes(newStoreValue[0]);
      this.maxMinutes = sToMinutes(newStoreValue[1]);
    };
    // Update maxMinutes and minMinutes when filter store chages
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => [state.filter.minDurationS, state.filter.maxDurationS],
      watchChangedFn.bind(this),
    ));
  }

  public beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      minMinutes: sToMinutes(this.$store.state.filter.minDurationS),
      maxMinutes: sToMinutes(this.$store.state.filter.maxDurationS),
    };
  }

  onOk() {
    this.$store.commit('filter/setFilter', {
      minDurationS: minutesToS(this.minMinutes),
      maxDurationS: minutesToS(this.maxMinutes),
    });
    this.$emit('close');
  }

  onClose() {
    this.$emit('close');
  }

  onClearMin() {
    this.minMinutes = null;
  }

  onClearMax() {
    this.maxMinutes = null;
  }
}
