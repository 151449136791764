/// CSS rules rely on these, so don't just change them here.
export enum DropDownMenuId {
  mode = 'mode',
  activity = 'activity',
  question = 'question',
  dateTime = 'date-time',
  advanced = 'advanced',
  // ActiveViewer
  gender = 'av-gender',
  age = 'av-age',
  distanceToWork = 'av-distance-to-work',
  workplaceSite = 'av-workplace-site',
}

export enum CustomItem {
  dateInterval = 'date-interval',
  timeInterval = 'time-interval',
  dayOfWeek= 'day-of-week',
  question = 'question', // acts kind of like separator/caption for the following answer options
}

export const NO_ANSWER_FILTER_ITEM_ID = -1;

export type MenuItem = {
  label: String;
  icon: String|undefined;
  iconSet: String|undefined;
  isCheck: boolean; // is checkbox item
  checked: boolean; // checkbox state
  filterItemId: number; // aggregated mode id, answer option key id etc.
  filterParentId: number; // question key id or -1
  custom: CustomItem|undefined; // custom rendering
  isMissingValue: boolean; // style as missing value item?
}
export type DropDownMenu = {
  id: DropDownMenuId;
  label: String;
  items: Array<MenuItem>;
  allChecked : boolean;
  // active = open. Not bould directly to the component, but we listen to events and maintain it
  // to be able to swap out the arrow icon.
  isActive: boolean;
}

export type DateTimeEdit = {
  startTime : Date|null;
  endTime : Date|null;
  dates : Array<Date>;
  dayOfWeek : Array<number>;
}

export enum AdvancedItems {
  homeArea = 1,
  tripDistance = 2,
  tripDuration = 3,
  filterEditor = 4,
  editAggregatedModes = 5,
  editAggregatedActivities = 6,
  resetAllFilters = 7,
  editQuerySettings = 8,
}

export enum Ma {
  mode = 'mode',
  activity = 'activity',
}
