import { Questionnaire, QuestionnaireType } from '@/store/modules/questionnaire/types';
import { SampleClass, SampleVariable } from '@/store/modules/sample/types';
import { RootState } from '@/store/root-state';

/**
 * Create a blank sample class
 * @param order The order value to assign
 */
export function sampleClassFactory(order : number|null): SampleClass {
  return {
    id: null,
    order,
    label: '',
    labelTranslations: null,
    collectedSample: null,
    percentage: null,
    targetPopulation: null,
    weightedSample: null,
    dataOverTime: [],
    answerOptionKeyId: null,
    binMin: null,
    binMax: null,
    areaId: null,
  } as SampleClass;
}

/**
 * Creates a blank sample variable. It will have null sourceType which
 * is generally not expected.
 * @param order The order value to assign
 */
export function sampleVariableFactory(order : number|null): SampleVariable {
  return {
    id: null,
    order,
    label: '',
    labelTranslations: null,
    canWeightOn: false, // server will fill out this
    weightOn: false,
    isShare: false, // server will fill out this
    multiMembership: false, // server will fill out this
    isExpanded: true,
    sourceType: null,
    questionKeyId: null,
    answerOptionKeyId: null,
    preFuncExpression: null,
    filterMin: null,
    filterMax: null,
    classes: [],
  } as any;
}

/**
 * Find sample variable that has given question key as source.
 * @param rootState $store.state
 * @param questionKey question key
 * @return SampleVariable or null
 */
export function findSampleVariableByQuestionKey(rootState : RootState, questionKey: string) : SampleVariable|null {
  const bgSurvey = rootState.questionnaire.questionnaires.find(
    (que) => que.type === QuestionnaireType.backgroundSurvey,
  );
  if (bgSurvey === undefined) {
    return null;
  }
  const q = bgSurvey.questions.find((q) => q.qKey === questionKey);
  if (q === undefined) {
    return null;
  }
  // now we have question key id. Find the sample variable with this key id.
  const sv = rootState.sample.variables.find(
    (sv) => sv.questionKeyId === q.qKeyId,
  );
  return sv ?? null;
}

/**
 * Find sample variable that has an answer option key as source.
 *
 * Using key in string representation and thus requires both question key + answer option key for
 * specification of the answer option key.
 *
 * @param rootState $store.state
 * @param questionKey question key string.
 * @param answerOptionKey answer option key string.
 * @return SampleVariable or null
 */
export function findSampleVariableByAnswerOptionKey(
  rootState: RootState,
  questionKey: string,
  answerOptionKey: string,
) : SampleVariable|null {
  const bgSurvey = (rootState.questionnaire.questionnaires as Array<Questionnaire>).find(
    (que) => que.type === QuestionnaireType.backgroundSurvey,
  );
  if (bgSurvey === undefined) {
    return null;
  }
  const q = bgSurvey.questions.find((q) => q.qKey === questionKey);
  if (q === undefined) {
    return null;
  }
  const ao = q.answerOptions.find((ao) => ao.aoKey === answerOptionKey);
  if (ao === undefined) {
    return null;
  }
  const sv = (rootState.sample.variables as Array<SampleVariable>).find(
    (sv) => sv.answerOptionKeyId === ao.aoKeyId,
  );
  return sv ?? null;
}
