export enum QuestionnaireType {
  backgroundSurvey = 'BG-SURVEY',
  tsTravel = 'TS-TRAVEL', // deprecated - replaced by ts
  ts = 'TS',
  date = 'DATE',
}

export enum QuestionType {
  singleAnswer = 'SINGLE_ANSWER',
  multiAnswer = 'MULTI_ANSWER',
  // rank = 'RANK', // Not implemented in TravelVu-app
  openText = 'OPEN_TEXT',
  integer = 'INTEGER',
  integerSum = 'INTEGER_SUM',
  // ...
  // some info-only types have been omitted
}

export interface AnswerOption {
  aoKeyId : number;
  aoKey : string;
  label : any; // map from langKey to string
  placeholder : any; // map from langKey to string
  inputType : string;
  pattern : string;
  hasFreeText : boolean;
  freeTextLabel : any; // map from langKey to string
}

export interface Question {
  qKeyId : number;
  qKey : string;
  type : QuestionType;
  label : any; // map from langKey to string
  order : number;
  infoText : any; // map from langKey to string
  unitLabel : any; // map from langKey to string
  inconsistent : boolean; // true if language variants have inconsistent
                          // non-translatable properties.
  inconsistentAttrs : Array<string>;
  answerOptions : Array<AnswerOption>;
}

export interface Questionnaire {
  type: QuestionnaireType;
  tsMode: string|null; // mode id or null
  questions: Array<Question>;
}

export interface QuestionnaireStateInterface {
  questionnaires : Array<Questionnaire>;
  loaded : boolean;
}
