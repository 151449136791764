




















































































import { Component, Vue } from 'vue-property-decorator';
import { AggregatedMode, AggregatedActivity, AggregatedModeConfig } from '@/store/modules/mode-activity/types';
import { SetByIdParams, SetSampleClassParams, EnableAllParams } from '@/store/modules/filter';
import resolveLabel from '@/utils/I18nLabel';
import {
  date2seconds, seconds2date, ymdString2date, date2YMDString,
} from '@/utils/dateHelpers';
import Unwatcher from '@/utils/unwatcher';
import AggregateEditComponent from '@/components/AggregateEditComponent.vue';
import TripDistanceEditComponent from '@/components/TripDistanceEditComponent.vue';
import TripDurationEditComponent from '@/components/TripDurationEditComponent.vue';
import { FilterStateInterface } from '@/store/modules/filter/types';
import { Survey } from '@/store/modules/survey/types';
import i18n from '@/i18n';
import { SetStartEndDateParams } from '@/store/modules/result-board';
import { DateTimeEdit, DropDownMenu, DropDownMenuId } from './types';

/** Shows filter menus to configure filter state. */
@Component({
  props: {
    title: String,
  },
})
export default class FilterBarComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  dropdown : DropDownMenu = {
    id: DropDownMenuId.dateTime,
    label: i18n.tc('analysis.menu.date-time'),
    isActive: false,
    items: [],
    allChecked: false,
  };

  dateTime : DateTimeEdit = {
    startTime: null,
    endTime: null,
    dates: [],
    dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
  };

  locale : string|undefined;
  dowStringsMonSun : Array<string> = []; // day of week strings 0: monday
  dowStringsSunSat : Array<string> = []; // day of week strings 0: sunday

  isWideScreen : boolean = false;

  public created() {
    // When filter date/time/dow changes => update UI state
    const dateTimeFilterChangedFn = (
      newStoreValue: Array<number>|null, oldStoreValue: Array<number>|null,
    ) => {
      this.dateTime = this.getDateTimeEditFromStore();
    };
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => [
        state.filter.startTime,
        state.filter.endTime,
        state.filter.startDate,
        state.filter.endDate,
        state.filter.dayOfWeek,
      ],
      dateTimeFilterChangedFn.bind(this),
      {
        deep: true,
      },
    ));
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      dateTime: this.getDateTimeEditFromStore(),
      locale: this.$i18n.locale,
      dowStringsMonSun: [
        this.$t('monday-short'),
        this.$t('tuesday-short'),
        this.$t('wednesday-short'),
        this.$t('thursday-short'),
        this.$t('friday-short'),
        this.$t('saturday-short'),
        this.$t('sunday-short'),
      ],
      dowStringsSunSat: [
        this.$t('sunday-short'),
        this.$t('monday-short'),
        this.$t('tuesday-short'),
        this.$t('wednesday-short'),
        this.$t('thursday-short'),
        this.$t('friday-short'),
        this.$t('saturday-short'),
      ],
      isWideScreen: this.getIsWideScreen(),
    };
  }

  getDateTimeEditFromStore() : DateTimeEdit {
    const filter : FilterStateInterface = this.$store.state.filter;
    const survey : Survey|null = this.$store.getters['survey/getSelected'];
    const startTime = seconds2date(filter.startTime);
    const endTime = seconds2date(filter.endTime);
    let startDate = filter.startDate !== null ? ymdString2date(filter.startDate) : null;
    let endDate = filter.endDate !== null ? ymdString2date(filter.endDate) : null;
    if (startDate === null && survey !== null && survey.startDate !== null) {
      startDate = ymdString2date(survey.startDate);
    }
    if (endDate === null && survey !== null && survey.endDate !== null) {
      endDate = ymdString2date(survey.endDate);
    }

    const dates = [];
    if (startDate !== null) {
      dates.push(startDate);
      if (endDate !== null) dates.push(endDate);
    }

    // Convert from a list of booleans to an array
    // of integers with only enabled days of week
    const dayOfWeek = [];
    for (let i = 0; i < 7; i += 1) {
      if (filter.dayOfWeek[i]) {
        dayOfWeek.push(i);
      }
    }

    return {
      startTime,
      endTime,
      dates,
      dayOfWeek,
    };
  }

  getIsWideScreen() : boolean {
    // 1216 is widescreen in bulma.
    return window.matchMedia('screen and (min-width: 1216px)').matches;
  }

  /* eslint no-param-reassign: ["error", { "props": false }] */
  /* eslint class-methods-use-this: ["off"] */
  onActiveChange(active : boolean) {
    this.dropdown.isActive = active;
    this.isWideScreen = this.getIsWideScreen();
  }

  /** Any date/time filter changed */
  onDateTimeChange() {
    // Update store
    let startDate : string|null = null;
    let endDate : string|null = null;

    if (this.dateTime.dates.length > 0) {
      startDate = date2YMDString(this.dateTime.dates[0]);
      endDate = date2YMDString(this.dateTime.dates[this.dateTime.dates.length - 1]);
    }

    // Store have an array of true/false
    // while the buefy model wants an array of
    // values including only enabled days of week.
    const dayOfWeek = [false, false, false, false, false, false, false];
    for (let i = 0; i < 7; i += 1) {
      dayOfWeek[i] = this.dateTime.dayOfWeek.indexOf(i) !== -1;
    }

    this.$store.commit('filter/setFilter', {
      startTime: this.dateTime.startTime !== null
        ? date2seconds(this.dateTime.startTime) : 0,
      endTime: this.dateTime.endTime !== null
        ? date2seconds(this.dateTime.endTime) : 24 * 3600 - 1,
      startDate,
      endDate,
      dayOfWeek,
    });
    this.$store.commit('resultBoard/setStartEndDate', {
      startDate,
      endDate,
    } as SetStartEndDateParams);
  }
}
