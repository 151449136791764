
















































import { Component, Prop, Vue } from 'vue-property-decorator';
import FilterBarComponent from './filter/FilterBarComponent.vue';

@Component({
  props: {
    viewTitle: String, // Title to show in header
  },
  components: {
    FilterBarComponent,
  },
})
export default class ViewLayoutComponent extends Vue {
  data() {
    const { user } = this.$store.state.user;
    return {
      username: user !== null ? user.username : '',
    };
  }

  onLogout() {
    this.$store.dispatch('user/logout').then(() => {
      this.$store.commit('survey/clear');
      this.$store.commit('sample/clear');
      this.$store.commit('modeActivity/clear');
      this.$store.commit('questionnaire/clear');
      this.$store.commit('query/clear');
      this.$store.commit('resultBoard/clear');
      this.$router.push('/login');
    });
  }

  profile() {
    this.$store.dispatch('user/getProfile');
  }
}
