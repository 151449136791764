




























































































import { Component, Vue } from 'vue-property-decorator';
import { SetByIdParams, SetSampleClassParams, EnableAllParams } from '@/store/modules/filter';
import Unwatcher from '@/utils/unwatcher';
import AggregateEditComponent from '@/components/AggregateEditComponent.vue';
import TripDistanceEditComponent from '@/components/TripDistanceEditComponent.vue';
import TripDurationEditComponent from '@/components/TripDurationEditComponent.vue';
import { Survey } from '@/store/modules/survey/types';
import QuerySettingsComponent from '../QuerySettingsComponent.vue';
import {
  AdvancedItems,
  DateTimeEdit,
  DropDownMenu,
  DropDownMenuId,
  Ma,
  MenuItem,
  NO_ANSWER_FILTER_ITEM_ID,
} from './types';
import {
  isSampleVariableDropDown,
  updateAllChecked,
} from './dropdownUtils';

/** Shows filter menus to configure filter state. */
@Component({
  props: {
    dropdown: {}, // DropDownMenu
  },
})
export default class FilterBarComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  dateTime : DateTimeEdit = {
    startTime: null,
    endTime: null,
    dates: [],
    dayOfWeek: [0, 1, 2, 3, 4, 5, 6],
  };

  locale : string|undefined;
  dowStringsMonSun : Array<string> = []; // day of week strings 0: monday
  dowStringsSunSat : Array<string> = []; // day of week strings 0: sunday

  editAggregatedModes: boolean = false;
  aggregateEditComponent = AggregateEditComponent; // 'constant' pointer to AggregatedModeComponent
  aggregateEditType : Ma = Ma.mode;
  editTripDistance: boolean = false;
  editTripDuration: boolean = false;
  editQuerySettings: boolean = false;
  tripDistanceEditComponent = TripDistanceEditComponent; // 'constant' pointer to comp
  tripDurationEditComponent = TripDurationEditComponent; // 'constant' pointer to comp
  querySettingsComponent = QuerySettingsComponent; // 'constant' pointer to comp
  isWideScreen : boolean = false;

  advancedMenuId = DropDownMenuId.advanced;

  public created() {
    const watchChangedFn = (
      newStoreValue: Array<number>|null, oldStoreValue: Array<number>|null,
    ) => {
      // On each item, update checked status of isCheck items
      this.$props.dropdown.items.forEach((menuItem : MenuItem) => {
        if (!menuItem.isCheck) return;
        if (newStoreValue === null) {
          menuItem.checked = false;
        } else if (isSampleVariableDropDown(this.$props.dropdown)) {
          menuItem.checked = this.$store.getters['filter/isSampleClassEnabled'](
            menuItem.filterParentId,
            menuItem.filterItemId === NO_ANSWER_FILTER_ITEM_ID ? null : menuItem.filterItemId,
          );
        } else {
          menuItem.checked = newStoreValue.indexOf(menuItem.filterItemId) !== -1;
        }
      });
      updateAllChecked(this.$props.dropdown);
    };

    if ((this.$props.dropdown as DropDownMenu).id === DropDownMenuId.mode) {
      // Update mode checked state when aggregated mode filter changes
      this.unwatcher.push(this.$store.watch(
        (state: any, getters: any) => state.filter.aggregatedModes,
        watchChangedFn,
      ));
    }

    if ((this.$props.dropdown as DropDownMenu).id === DropDownMenuId.activity) {
      // Update activity checked state when aggregated activity filter changes
      this.unwatcher.push(this.$store.watch(
        (state: any, getters: any) => state.filter.aggregatedActivities,
        watchChangedFn,
      ));
    }

    if (isSampleVariableDropDown(this.$props.dropdown)) {
      // Update question menu and ActiveViewer menus checked state when sample class filter changes
      this.unwatcher.push(this.$store.watch(
        (state: any, getters: any) => state.filter.sampleClassIds,
        watchChangedFn,
      ));
    }
  }

  beforeDestroy() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      locale: this.$i18n.locale,
      isWideScreen: this.getIsWideScreen(),
    };
  }

  getIsWideScreen() : boolean {
    // 1216 is widescreen in bulma.
    return window.matchMedia('screen and (min-width: 1216px)').matches;
  }

  /* eslint no-param-reassign: ["error", { "props": false }] */
  /* eslint class-methods-use-this: ["off"] */
  onActiveChange(active : boolean) {
    this.$props.dropdown.isActive = active;
    this.isWideScreen = this.getIsWideScreen();
  }

  /** A non-checkbox menuitem was clicked */
  onMenuItemClick(dropdown : DropDownMenu, menuitem : MenuItem) {
    if (menuitem.isCheck) {
      return;
    }
    if (dropdown.id === DropDownMenuId.advanced) {
      this.editTripDistance = false;
      this.editTripDuration = false;
      this.editAggregatedModes = false;
      this.editQuerySettings = false;
      if (menuitem.filterItemId === AdvancedItems.tripDistance) {
        this.editTripDistance = true;
      } else if (menuitem.filterItemId === AdvancedItems.tripDuration) {
        this.editTripDuration = true;
      } else if (menuitem.filterItemId === AdvancedItems.editAggregatedModes) {
        this.aggregateEditType = Ma.mode;
        setTimeout(() => {
          this.editAggregatedModes = true;
        }, 1);
      } else if (menuitem.filterItemId === AdvancedItems.editAggregatedActivities) {
        this.aggregateEditType = Ma.activity;
        setTimeout(() => {
          this.editAggregatedModes = true;
        }, 1);
      } else if (menuitem.filterItemId === AdvancedItems.editQuerySettings) {
        this.editQuerySettings = true;
      } else if (menuitem.filterItemId === AdvancedItems.resetAllFilters) {
        // Reset -> Enable all filters
        const survey : Survey|null = this.$store.getters['survey/getSelected'];
        if (survey !== null) {
          this.$store.commit('filter/enableAll', {
            aggregatedModes: this.$store.getters['modeActivity/getAggregatedModesExcludingExercice'],
            aggregatedActivities: this.$store.state.modeActivity.aggregatedActivities,
            sampleVariables: this.$store.state.sample.variables,
            surveyStartDate: survey.startDate,
            surveyEndDate: survey.endDate,
          } as EnableAllParams);
        } else {
          // This error should be rare - so don't bother with translation
          this.$buefy.toast.open({
            message: 'No survey selected',
            type: 'is-assertive',
            position: 'is-bottom',
          });
        }
      } else {
        this.$buefy.snackbar.open('Feature not yet available');
      }
    }
  }

  /** A checkbox of a menuitem was toggled */
  onToggle(dropdown : DropDownMenu, menuitem: MenuItem) {
    // Update dropdown.allChecked
    updateAllChecked(dropdown);

    // Update filter
    if (isSampleVariableDropDown(dropdown)) {
      const classId = menuitem.filterItemId === NO_ANSWER_FILTER_ITEM_ID
        ? null : menuitem.filterItemId;
      this.$store.commit('filter/setAnswerOptionKeyId', {
        variableId: menuitem.filterParentId,
        classId,
        enabled: menuitem.checked,
      } as SetSampleClassParams);
    } else {
      const mutationMap : any = {};
      mutationMap[DropDownMenuId.mode] = 'filter/setAggregatedMode';
      mutationMap[DropDownMenuId.activity] = 'filter/setAggregatedActivity';
      const mutation = mutationMap[dropdown.id];
      if (mutation !== undefined) {
        this.$store.commit(mutation, {
          id: menuitem.filterItemId,
          enabled: menuitem.checked,
        } as SetByIdParams);
      }
    }
  }

  /** The toggle all checkbox was toggled */
  onToggleAll(dropdown : DropDownMenu) {
    dropdown.items.forEach((item) => {
      item.checked = dropdown.allChecked;
    });

    // Update filter
    const mutationMap : any = {
      mode: 'filter/setAggregatedMode',
      activity: 'filter/setAggregatedActivity',
    };
    const mutation = mutationMap[dropdown.id];
    if (mutation !== undefined) {
      dropdown.items.forEach((menuitem) => {
        this.$store.commit(mutation, {
          id: menuitem.filterItemId,
          enabled: menuitem.checked,
        } as SetByIdParams);
      });
    }
  }
}
