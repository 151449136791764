






















import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '../utils/unwatcher';

/** Shows */
@Component({
  props: {
    text: {}, // String
    pre: {}, // boolean - use <pre>?
  },
})
export default class ExpandableDescriptionComponent extends Vue {
  expanded: boolean = false;

  onToggle(textTrigger : boolean) {
    if (!textTrigger || !this.expanded) {
      this.expanded = !this.expanded;
    }
  }
}
