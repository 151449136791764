




























































































































import { Component, Vue } from 'vue-property-decorator';
import draggable from 'vuedraggable';
import {
  getMainAggregateActivities,
  getMainAggregateModes,
  getNoAggregateActivities,
  getNoAggregateModes,
} from '@/utils/maAggregate';
import palette from '@/utils/palette';
import Log from '@/utils/log';
import i18n from '../i18n';
import {
  Mode, Activity, AggregatedMode, AggregatedActivity,
  AggregatedModeConfig, AggregatedActivityConfig,
} from '../store/modules/mode-activity/types';
import Unwatcher from '../utils/unwatcher';
import { RootState } from '../store/root-state';
import resolveLabel from '../utils/I18nLabel';

interface MaInfo {
  label : string;
  inSurvey : boolean;
}

interface MaAggregateInfo {
  id : number; // aggregate id
  key : string;
  label : string;
  style : string;
  maList : Array<MaInfo>;
}

/** Shows the current filter state. */
@Component({
  props: {
    type: {}, // 'mode' or 'activity'
  },
  components: {
    draggable,
  },
})
export default class AggregateEditComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  aggregateInfo : Array<MaAggregateInfo> = [];
  // Copy of aggegatedMode or aggregatedActivity in store. commited in onOk()
  aggregateConfig : Array<AggregatedMode|AggregatedActivity> = [];
  showNotInSurvey : boolean = false;

  public created() {
    const watchChangedFn = (
      newStoreValue: AggregatedModeConfig|AggregatedActivityConfig|null,
      oldStoreValue: AggregatedModeConfig|AggregatedActivityConfig|null,
    ) => {
      if (newStoreValue !== null) {
        this.aggregateInfo = this.buildInfo(newStoreValue);
      } else {
        this.aggregateInfo = [];
      }
    };
    // Update aggregatedMa when aggregated modes/activities changes
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => (this.$props.type === 'mode'
        ? state.modeActivity.aggregatedModes : state.modeActivity.aggregatedActivities),
      watchChangedFn.bind(this),
    ));
  }

  public beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      aggregateInfo: this.buildInfo(
        this.$props.type === 'mode' ? this.$store.state.modeActivity.aggregatedModes
          : this.$store.state.modeActivity.aggregatedActivities,
      ),
      aggregateConfig: JSON.parse(JSON.stringify(
        this.$props.type === 'mode'
          ? this.$store.state.modeActivity.aggregatedModes
          : this.$store.state.modeActivity.aggregatedActivities,
      )),
    };
  }

  static buildModeStyle(mode : AggregatedMode): string {
    return `
      background-color: ${mode.bgColor};
      color: ${mode.textColor};
    `;
    // border-bottom: 3px solid ${mode.borderColor}
  }

  buildInfo(config : AggregatedModeConfig|AggregatedActivityConfig): Array<MaAggregateInfo> {
    const r : Array<MaAggregateInfo> = [];
    config.forEach((maAggregate : AggregatedMode|AggregatedActivity) => {
      const isMode = (maAggregate as AggregatedMode).modeList !== undefined;
      const maInfo = {
        id: maAggregate.id,
        label: resolveLabel(null, maAggregate.labelTranslations),
        maList: [],
        style: isMode ? AggregateEditComponent.buildModeStyle((maAggregate as AggregatedMode)) : '',
        key: '',
      } as MaAggregateInfo;

      const list = isMode ? (maAggregate as AggregatedMode).modeList
        : (maAggregate as AggregatedActivity).activityList;
      list.forEach((id : string) => {
        const ma : Mode|Activity|null = isMode
          ? this.$store.getters['modeActivity/getMode'](id)
          : this.$store.getters['modeActivity/getActivity'](id);
        if (ma !== null) {
          resolveLabel(null, ma.labelTranslations);
          maInfo.maList.push({
            label: resolveLabel(null, ma.labelTranslations),
            inSurvey: ma.inSurvey,
          });
        } else {
          maInfo.maList.push({
            label: id,
            inSurvey: false,
          });
        }
      });

      maInfo.key = `${maInfo.label}:${maInfo.maList.map((ma : MaInfo) => ma.label).join(',')}`;
      r.push(maInfo);
    });

    return r;
  }

  onNoAggregate() {
    this.aggregateConfig = this.$props.type === 'mode'
      ? getNoAggregateModes(this.$store.state.modeActivity.modes)
      : getNoAggregateActivities(this.$store.state.modeActivity.activities);
    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
  }

  onMainAggregate() {
    this.aggregateConfig = this.$props.type === 'mode'
      ? getMainAggregateModes(this.$store.state.modeActivity.modes)
      : getMainAggregateActivities(this.$store.state.modeActivity.activities);
    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
  }

  /** MA drag end */
  onMaDragEnd(event : any) : boolean {
    if (event.from.attributes['data-ma-id'].value !== event.to.attributes['data-ma-id'].value) {
      // Draged MA to different aggregate
      let moved : string|null = null;
      this.aggregateConfig = this.aggregateConfig.map((a) => {
        if (a.id === parseInt(event.from.attributes['data-ma-id'].value, 10)) {
          const list = this.$props.type === 'mode'
            ? (a as AggregatedMode).modeList
            : (a as AggregatedActivity).activityList;
          moved = list[event.oldIndex];
          list.splice(event.oldIndex, 1);
        }
        return a;
      });
      if (moved === null) {
        Log.log('Failed to move');
        return false;
      }
      this.aggregateConfig = this.aggregateConfig.map((a) => {
        if (a.id === parseInt(event.to.attributes['data-ma-id'].value, 10)) {
          const list = this.$props.type === 'mode'
            ? (a as AggregatedMode).modeList
            : (a as AggregatedActivity).activityList;
          const wasEmpty = list.length === 0;
          list.splice(event.newIndex, 0, moved!);
          if (wasEmpty && this.$props.type === 'mode') {
            this.cycleToNextColor(a.id);
          }
        }
        return a;
      });
    } else {
      // Dragged within aggregate
      this.aggregateConfig = this.aggregateConfig.map((a) => {
        if (a.id === event.from.attributes['data-ma-id']) {
          const list = this.$props.type === 'mode'
            ? (a as AggregatedMode).modeList
            : (a as AggregatedActivity).activityList;
          const moved = list[event.oldIndex];
          list.splice(event.oldIndex, 1);
          let newIndex = event.newIndex;
          if (event.oldIndex < newIndex) {
            newIndex -= 1;
          }
          list.splice(newIndex, 0, moved);
        }
        return a;
      });
    }

    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
    return true;
  }

  resetFilter() {
    if (this.$props.type === 'mode') {
      this.$store.commit('filter/setFilter', {
        aggregatedModes: this.$store.getters['modeActivity/getAggregatedModesExcludingExercice'].map(
          (m : Mode) => m.id,
        ),
      });
    } else {
      this.$store.commit('filter/setFilter', {
        aggregatedActivities: this.$store.state.modeActivity.aggregatedActivities.map(
          (a : Activity) => a.id,
        ),
      });
    }

    // Inform user that filter was reset
    this.$buefy.dialog.alert({
      message: this.$i18n.tc(`analysis.aggregate.${this.$props.type}-filter-reset`),
      container: 'main',
    } as any); // any: container field is missing in buefy
  }

  /**
   * Upon click on aggregate color, cycle the colors
   * from the mode colors among member modes.
   */
  onAggregateColorClick(aggregate : MaAggregateInfo) {
    this.cycleToNextColor(aggregate.id);
    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
  }

  cycleToNextColor(aggregateId : number) {
    const am : AggregatedMode|undefined = (this.aggregateConfig as Array<AggregatedMode>).find(
      (a) => a.id === aggregateId,
    );
    if (am === undefined) return;

    // Find which mode that aggregate currently picks color from
    const visibleModeList : Array<Mode> = []; // visible and unique color modes
    am.modeList.forEach((id) => {
      const mode : Mode|null = this.$store.getters['modeActivity/getMode'](id);
      if (mode !== null && (mode.inSurvey || this.showNotInSurvey)) {
        if (visibleModeList.find((m) => m.bgColor === mode.bgColor) === undefined) {
          visibleModeList.push(mode);
        }
      }
    });
    let colorIndex = -1;
    for (let i = 0; i < visibleModeList.length; i += 1) {
      const mode = visibleModeList[i];
      if (mode.bgColor === am.bgColor) {
        colorIndex = i;
      }
    }

    if (visibleModeList.length === 0) {
      return;
    }

    // Take next color
    colorIndex += 1;
    if (colorIndex >= visibleModeList.length) {
      colorIndex = 0;
    }

    // Apply
    const mode : Mode = visibleModeList[colorIndex];
    am.bgColor = mode.bgColor;
    am.textColor = mode.textColor;
    am.borderColor = mode.borderColor;
  }

  onLabelInput(aggregate : MaAggregateInfo) {
    this.aggregateConfig = this.aggregateConfig.map((a) => {
      if (a.id === aggregate.id) {
        const aa = { ...a };
        aa.labelTranslations[i18n.locale] = aggregate.label;
        return aa;
      }
      return a;
    });
  }

  onDeleteAggregate(aggregate : MaAggregateInfo) {
    const removedMas : string[] = [];
    let otherId : number|null = null;
    this.aggregateConfig.forEach((a) => {
      const list = this.$props.type === 'mode'
        ? (a as AggregatedMode).modeList
        : (a as AggregatedActivity).activityList;
      if (a.id === aggregate.id) {
        removedMas.push(...list);
      }
      if (list.indexOf('OTHR') !== -1) {
        otherId = a.id;
      }
    });
    if (removedMas.length > 0) {
      if ((otherId === null || otherId === aggregate.id)
        && this.aggregateConfig.length < 2
      ) {
        this.$buefy.dialog.alert({
          message: 'Cannot remove last aggregate',
          type: 'is-assertive',
          container: 'main',
        } as any); // any: container field is missing in buefy
        return;
      }
      const moveToId = otherId !== null && otherId !== aggregate.id
        ? otherId
        : this.aggregateConfig[this.aggregateConfig.length
          - (this.aggregateConfig[this.aggregateConfig.length - 1].id === aggregate.id
            ? 2 : 1
          )
        ].id;
      this.aggregateConfig = this.aggregateConfig.map((a) => {
        if (a.id === moveToId) {
          const list = this.$props.type === 'mode'
            ? (a as AggregatedMode).modeList
            : (a as AggregatedActivity).activityList;
          list.push(...removedMas);
        }
        return a;
      });
    }

    this.aggregateConfig = this.aggregateConfig.filter((a) => a.id !== aggregate.id);
    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
  }

  onAddAggregate(aggregate : MaAggregateInfo) {
    let maxId : number|null = null;
    let maxOrder : number|null = null;
    this.aggregateConfig.forEach((a) => {
      if (maxId === null || a.id > maxId) {
        maxId = a.id;
      }
      if (maxOrder === null || a.order > maxOrder) {
        maxOrder = a.order;
      }
    });
    if (maxId === null) {
      maxId = 0;
    }
    if (maxOrder === null) {
      maxOrder = 0;
    }
    this.aggregateConfig.push(
      this.$props.type === 'mode'
        ? {
          id: maxId + 1,
          order: maxOrder + 1,
          labelTranslations: {},
          bgColor: palette[2], // same color as OTHR
          textColor: palette['2a'],
          borderColor: palette['2c'],
          svgIconBgColor: '',
          svgIconBlack: '',
          modeList: [],
        } as AggregatedMode
        : {
          id: maxId + 1,
          order: maxOrder + 1,
          labelTranslations: {},
          svgIconBgColor: '',
          svgIconBlack: '',
          activityList: [],
        } as AggregatedActivity,
    );
    this.aggregateInfo = this.buildInfo(this.aggregateConfig as any);
  }

  onOk() {
    const modification = this.$props.type === 'mode'
      ? 'modeActivity/setAggregatedModes' : 'modeActivity/setAggregatedActivities';
    this.$store.commit(modification, this.aggregateConfig);
    this.resetFilter();
    this.$emit('close');
  }

  onClose() {
    this.$emit('close');
  }
}
