/* eslint-disable import/prefer-default-export */
import { DropDownMenu, DropDownMenuId } from './types';

/// Update dropdown.allChecked
export function updateAllChecked(dropdown: DropDownMenu): void {
  const allChecked = dropdown.items.reduce((all, item) => all && item.checked, true);
  // eslint-disable-next-line no-param-reassign
  dropdown.allChecked = allChecked;
}

/**
 * Is the drowpdown a dropdown for filtering one or more SampleVariables?
 * @param dropdown
 * @returns True or false
 */
export function isSampleVariableDropDown(dropdown: DropDownMenu): boolean {
  return [
    DropDownMenuId.question,
    DropDownMenuId.gender,
    DropDownMenuId.age,
    DropDownMenuId.distanceToWork,
    DropDownMenuId.workplaceSite,
  ].indexOf(dropdown.id) !== -1;
}
