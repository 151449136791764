var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('b-dropdown',{class:_vm.dropdown.id,attrs:{"aria-role":"list","mobile-modal":false,"position":_vm.dropdown.id === _vm.advancedMenuId &&
      !_vm.isWideScreen
      ? 'is-bottom-left' : 'is-bottom-right'},on:{"active-change":function($event){return _vm.onActiveChange.apply(void 0, arguments)}}},[_c('button',{staticClass:"button is-white ",attrs:{"slot":"trigger"},slot:"trigger"},[_c('span',[_vm._v(_vm._s(_vm.dropdown.label))]),_c('b-icon',{attrs:{"icon":_vm.dropdown.isActive ? 'menu-up' : 'menu-down'}})],1),(_vm.dropdown.id === 'mode' || _vm.dropdown.id === 'activity')?_c('b-dropdown-item',{staticClass:"toggle-all",attrs:{"aria-role":"listitem","custom":"","focusable":false}},[_c('b-checkbox',{on:{"input":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.onToggleAll.apply(void 0, [ _vm.dropdown ].concat( argsArray ))}},model:{value:(_vm.dropdown.allChecked),callback:function ($$v) {_vm.$set(_vm.dropdown, "allChecked", $$v)},expression:"dropdown.allChecked"}},[_c('span',{attrs:{"aria-label":_vm.$t('aria-toggle-all')}})])],1):_vm._e(),_vm._l((_vm.dropdown.items),function(menuitem){return _c('b-dropdown-item',{key:menuitem.label + menuitem.filterItemId + '-' + menuitem.filterParentId,attrs:{"aria-role":"listitem","custom":menuitem.isCheck,"focusable":!menuitem.isCheck},on:{"click":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.onMenuItemClick.apply(void 0, [ _vm.dropdown, menuitem ].concat( argsArray ))}}},[(!menuitem.isCheck)?_c('div',[(menuitem.icon !== undefined)?_c('b-icon',{attrs:{"icon":menuitem.icon,"iconset":menuitem.iconset,"size":"is-small"}}):_vm._e(),_c('span',[_vm._v(_vm._s(menuitem.label))])],1):_c('b-field',[_c('b-checkbox',{class:menuitem.isMissingValue ? 'is-missing-item' : '',attrs:{"native-value":menuitem.label},on:{"input":function($event){
      var i = arguments.length, argsArray = Array(i);
      while ( i-- ) argsArray[i] = arguments[i];
return _vm.onToggle.apply(void 0, [ _vm.dropdown, menuitem ].concat( argsArray ))}},model:{value:(menuitem.checked),callback:function ($$v) {_vm.$set(menuitem, "checked", $$v)},expression:"menuitem.checked"}},[(menuitem.icon !== undefined)?_c('b-icon',{attrs:{"icon":menuitem.icon,"iconset":menuitem.iconset,"size":"is-small"}}):_vm._e(),_c('span',[_vm._v(_vm._s(menuitem.label))])],1)],1)],1)})],2),(_vm.dropdown.id === _vm.advancedMenuId)?_c('b-modal',{attrs:{"active":_vm.editAggregatedModes,"component":_vm.aggregateEditComponent,"props":{ type: _vm.aggregateEditType },"has-modal-card":"","can-cancel":['escape', 'outside']},on:{"update:active":function($event){_vm.editAggregatedModes=$event}}}):_vm._e(),(_vm.dropdown.id === _vm.advancedMenuId)?_c('b-modal',{attrs:{"active":_vm.editTripDistance,"component":_vm.tripDistanceEditComponent,"has-modal-card":"","can-cancel":['escape', 'outside']},on:{"update:active":function($event){_vm.editTripDistance=$event}}}):_vm._e(),(_vm.dropdown.id === _vm.advancedMenuId)?_c('b-modal',{attrs:{"active":_vm.editTripDuration,"component":_vm.tripDurationEditComponent,"has-modal-card":"","can-cancel":['escape', 'outside']},on:{"update:active":function($event){_vm.editTripDuration=$event}}}):_vm._e(),(_vm.dropdown.id === _vm.advancedMenuId)?_c('b-modal',{attrs:{"active":_vm.editQuerySettings,"component":_vm.querySettingsComponent,"has-modal-card":"","can-cancel":['escape', 'outside']},on:{"update:active":function($event){_vm.editQuerySettings=$event}}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }