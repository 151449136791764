




































































import { Component, Vue } from 'vue-property-decorator';
import Unwatcher from '../utils/unwatcher';

function mToKm(m : number|null) : number|null {
  return m !== null ? m / 1000 : null;
}

function kmToM(km : number|null) : number|null {
  return km !== null ? km * 1000 : null;
}

/** Shows the current filter state. */
@Component({
})
export default class TripDistanceEditComponent extends Vue {
  unwatcher : Unwatcher = new Unwatcher();
  minKm : number|null = null;
  maxKm : number|null = null;

  public created() {
    const watchChangedFn = (
      newStoreValue: Array<any>,
      oldStoreValue: Array<any>,
    ) => {
      this.minKm = mToKm(newStoreValue[0]);
      this.maxKm = mToKm(newStoreValue[1]);
    };
    // Update maxKm and minKm when filter store chages
    this.unwatcher.push(this.$store.watch(
      (state: any, getters: any) => [state.filter.minDistanceM, state.filter.maxDistanceM],
      watchChangedFn.bind(this),
    ));
  }

  public beforeDestory() {
    this.unwatcher.unwatchAll();
  }

  data() {
    return {
      minKm: mToKm(this.$store.state.filter.minDistanceM),
      maxKm: mToKm(this.$store.state.filter.maxDistanceM),
    };
  }

  onOk() {
    this.$store.commit('filter/setFilter', {
      minDistanceM: kmToM(this.minKm),
      maxDistanceM: kmToM(this.maxKm),
    });
    this.$emit('close');
  }

  onClose() {
    this.$emit('close');
  }

  onClearMin() {
    this.minKm = null;
  }

  onClearMax() {
    this.maxKm = null;
  }
}
